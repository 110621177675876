.Application {

}

.Title {

}

.Description {

}

.Input {
    margin-top: 30px;
}

.Options {
    margin-top: 30px;
}

.Option {
    margin-top: 30px;
}