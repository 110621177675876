.Application {

}

.Title {

}

.Description {

}

.PreviewTitle {
    padding: 5px;
    text-align: center;
    font-weight: bold;
}

.PreviewBox {
    border: 1px solid gainsboro;
    border-radius: 3px;
    min-height: 200px;
    max-height: 450px;
    padding: 25px;
    overflow:scroll;
}

.Input {
    margin-top: 30px;
}

.Options {
    margin-top: 30px;
}

.Option {
    margin-top: 30px;
}