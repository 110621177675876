.Application {

}

.Title {

}

.Description {

}

.PrettyJsonBox {
    border: 1px solid black;
    border-radius: 3px;
    min-height: 200px;
}

.Input {
    margin-top: 30px;
}

.Options {
    margin-top: 30px;
}

.Option {
    margin-top: 30px;
}