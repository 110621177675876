.Layout{
    /* border: 1px solid black; */
}

.Brand {
    color: rgb(83, 83, 172);
}

.Content{
    border: 1px solid black;
    margin: 20px;
    padding: 20px;
    border-radius: 3px;
}

@media (min-width: 500px) {
    .Content {
        margin: 50px;
        padding: 50px;
    }
}